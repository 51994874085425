<script lang="ts" setup>
	import type { IMuxVideo } from "~/server/models/mixins/muxVideo";

	const mux: IMuxVideo = {
		id: "ppkOeHc8PCD4ZyjGaXZ1DuZpY8Dsgl8bssyewfKeJmo",
		playback_id: "442cgAJRS9mqh7WIp00SDCRfLBGtlnQpXeFuUTdrHZbk",
	};

	const touch = useState("touch", () => false);
</script>

<template>
	<div class="home-player">
		<div class="shadow"></div>

		<MuxPlayer :mux="mux" />

		<div class="border"></div>
	</div>
</template>

<style scoped lang="scss">
	.home-player {
		position: relative;
		display: flex;
		height: auto;
		width: auto;
		flex-direction: row;
		border-radius: 42px;

		@media screen and (max-height: 900px) {
			border-radius: 38px;
		}

		.mux-player,
		.mux-gif-player {
			z-index: 6;
			width: auto;
			height: 100%;
			overflow: hidden;
			border-radius: inherit;
			padding: 12px;
			@media screen and (max-height: 900px) {
				padding: 8px;
			}
		}

		.mux-gif-player {
			min-width: 250px;
		}

		.shadow {
			z-index: 5;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: var(--yellow);
			border-radius: inherit;
			filter: drop-shadow(0px 89.2596px 118.083px rgba(0, 0, 0, 0.1)) drop-shadow(0px 57.8534px 69.1551px rgba(0, 0, 0, 0.0759259)) drop-shadow(0px 34.3815px 37.6116px rgba(0, 0, 0, 0.0607407))
				drop-shadow(0px 17.8519px 19.1885px rgba(0, 0, 0, 0.05)) drop-shadow(0px 7.273px 9.62158px rgba(0, 0, 0, 0.0392593)) drop-shadow(0px 1.65296px 4.64678px rgba(0, 0, 0, 0.0240741));
		}

		.border {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: inherit;
			box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 1) inset;
			z-index: 10;
			border: 4px solid rgba(0, 0, 0, 0.3);

			@media screen and (max-height: 900px) {
				box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 1) inset;
			}
		}

		svg {
			z-index: 10;
			float: left;
		}
	}
</style>
