<script lang="ts" setup>
	import type { IPost } from "~/server/models/post";

	const touch = useState("touch", () => false);

	const data = useState<IPost[]>("posts", () => []);

	if (process.server || data.value.length === 0) {
		data.value = await $fetch<IPost[]>("/api/posts/feed");
	}

	const firstVideo = computed(() => {
		if (!data.value || data.value?.length === 0) return null;
		return data.value![2]!.mux!;
	});

	const secondVideo = computed(() => {
		if (!data.value || data.value?.length === 0) return null;
		return data.value![5]!.mux!;
	});

	const thirdVideo = computed(() => {
		if (!data.value || data.value?.length === 0) return null;
		return data.value![10]!.mux!;
	});

	const fourthVideo = computed(() => {
		if (!data.value || data.value?.length === 0) return null;
		return data.value![15]!.mux!;
	});

	const fifthVideo = computed(() => {
		if (!data.value || data.value?.length === 0) return null;
		return data.value![20]!.mux!;
	});

	const first = ref<HTMLElement | null>(null);
	const second = ref<HTMLElement | null>(null);
	const third = ref<HTMLElement | null>(null);
	const fourth = ref<HTMLElement | null>(null);
	const fifth = ref<HTMLElement | null>(null);

	if (!touch.value) {
		const { x, y } = useMouse();
		const { width, height } = useWindowSize();

		watch([x, y, width, height], () => {
			if (first.value && second.value && third.value && fourth.value && fifth.value) {
				const multiplier = 0.08;
				const diff = 1.4;

				const horizontal = width.value / 2 - x.value;
				const vertical = height.value / 2 - y.value;

				first.value.style.transform = `translate(${horizontal * multiplier}px, ${vertical * multiplier}px)`;
				second.value.style.transform = `translate(${horizontal * multiplier * diff}px, ${vertical * multiplier}px)`;
				third.value.style.transform = `translate(${horizontal * multiplier}px, ${vertical * multiplier * diff}px)`;
				fourth.value.style.transform = `translate(${horizontal * multiplier * diff}px, ${vertical * multiplier}px)`;
				fifth.value.style.transform = `translate(${horizontal * multiplier}px, ${vertical * multiplier * diff}px)`;
			}
		});
	}
</script>

<template>
	<main class="hero flex-vertical">
		<div class="content flex-vertical">
			<h1>Charades reimagined in videos</h1>
			<p class="large">Watch short videos, guess the word, and dive into endless laughter!</p>

			<div class="flex-horizontal links">
				<AppStoreIcon />
				<QrCode />
			</div>
			<HomePlayer />

			<div class="videos" v-if="!touch">
				<div ref="first" class="first"><MuxPlayer v-if="firstVideo" :mux="firstVideo" /></div>
				<div ref="second" class="second"><MuxPlayer v-if="secondVideo" :mux="secondVideo" /></div>
				<div ref="third" class="third"><MuxPlayer v-if="thirdVideo" :mux="thirdVideo" /></div>
				<div ref="fourth" class="fourth"><MuxPlayer v-if="fourthVideo" :mux="fourthVideo" /></div>
				<div ref="fifth" class="fifth"><MuxPlayer v-if="fifthVideo" :mux="fifthVideo" /></div>
			</div>
		</div>
	</main>
</template>

<style scoped lang="scss">
	main {
		.content {
			.links {
				gap: 12px;
				margin-top: 20px;
				z-index: 20;
			}

			h1,
			p {
				z-index: 20;
			}

			.videos {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				pointer-events: none;

				@include mobile {
					display: none;
				}

				.first,
				.second,
				.third,
				.fourth,
				.fifth {
					position: absolute;
					opacity: 0;
					border-radius: 20px;
					overflow: hidden;
					display: flex;
					transition: all 0.4s ease-out;
					.mux-gif-player,
					.mux-player {
						height: auto;
					}
				}

				.first {
					left: 24px;
					bottom: calc(-30px / 800px * 100vh);
					.mux-gif-player,
					.mux-player {
						width: calc(206px / 1600px * 100vw);
					}
					opacity: 0.5;
				}

				.second {
					left: calc(310px / 1600px * 100vw);
					top: calc(150px / 800px * 100vh);
					.mux-gif-player,
					.mux-player {
						width: calc(140px / 1600px * 100vw);
					}

					opacity: 0.8;
				}

				.third {
					.mux-gif-player,
					.mux-player {
						width: calc(150px / 1600px * 100vw);
					}

					bottom: calc(-20px / 800px * 100vh);
					opacity: 0.8;
					left: calc(360px / 1600px * 100vw);
				}

				.fourth {
					.mux-gif-player,
					.mux-player {
						width: calc(180px / 1600px * 100vw);
					}

					opacity: 0.8;
					right: calc(288px / 1600px * 100vw);
					bottom: -30px;
				}

				.fifth {
					.mux-gif-player,
					.mux-player {
						width: calc(150px / 1600px * 100vw);
					}

					opacity: 0.8;
					right: calc(80px / 1600px * 100vw);
					top: calc(190px / 800px * 100vh);
				}
			}

			:deep(.home-player) {
				margin-top: 30px;
				height: calc(100% - 56px - 16px - 16px - 28px - 28px - 28px - 28px);
				max-height: 576px;
				@include mobile {
					height: 576px;
				}
				svg {
					width: auto;
					height: 100%;
				}
			}
		}
	}
</style>
